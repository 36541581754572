body {
  margin: 0;
  font-family: "Mukta", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.UHNavHide {
  visibility: hidden;
}
.UHNavShow {
  visibility: visible;
}
.UHNavStandardHide {
  visibility: hidden;
}
.UHNavStandardShow {
  visibility: visible;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1100ms ease-out, transform 2s ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
